import "../styling/Chatbot.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import "../App.css";
import Chatbot from "./Chatbot";
import Navbar from "./Navbar";

function Home() {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    async function fetchVideos() {
      try {
        // First, try fetching from the backend
        const backendResponse = await axios.get("http://localhost:5000/api/videos", { timeout: 5000 }); // 5s timeout
        if (backendResponse.data.length > 0) {
          console.log("Fetched from backend.");
          setVideos(backendResponse.data);
          return;
        }
      } catch (error) {
        console.warn("Backend not responding or empty. Fetching from YouTube API...");
      }

      // If backend fails, fetch directly from YouTube API
      try {
        const API_KEY = "AIzaSyAK4P86aE925e3krZw6EujBHy9i9TvFJXI"; // Replace with your actual YouTube Data API key
        const CHANNEL_ID = "UCox71ZE9ynlROFtml7ElWrA"; // Replace with the desired Channel ID

        const youtubeResponse = await axios.get(
          `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=date&maxResults=20`
        );

        const youtubeVideos = youtubeResponse.data.items.map((item) => ({
          id: item.id.videoId,
          title: item.snippet.title.replace(/&#39;/g, "'").replace(/&amp;/g, '&').replace(/&quot;/g, '"'),
          description: item.snippet.description,
          url: `https://www.youtube.com/embed/${item.id.videoId}`
        }));

        console.log("Fetched from YouTube API.");
        setVideos(youtubeVideos);
      } catch (error) {
        console.error("Error fetching video IDs from YouTube API:", error);
      }
    }

    fetchVideos();
  }, []);

  return (
    <div className="App">
      <Navbar />
      <Chatbot />

      <div className="all-content">
        <h1 className="website-title">IMMIGRANTS SUCCEED</h1>

        <div className="bigraphy">
          <p className="bi-text">
            As a first-generation low-income, refugee, who moved to the U.S. and
            struggled my way into getting to UC Berkeley, I want to share with
            the world what I've learned; so, that other immigrants (hopefully)
            don't experience the difficulties/challenges I had to go through,
            and become inspired that anything is possible, no matter how much
            one is at disadvantage!
          </p>
          <div className="image-cover">
            <div className="image" alt="Header"></div>
          </div>
        </div>

        <div className="videos-title">
          <h2>Videos</h2>
        </div>

        <div className="videos">
          {videos.map((video, index) => (
            <div className="content-container" key={index}>
              <div className="video-title">{video.title}</div>

              <div className="video-container">
                <iframe
                  className="video"
                  width="250"
                  height="170"
                  src={video.url}
                  title={`YouTube video ${index}`}
                  allowFullScreen
                ></iframe>
              </div>

              <div className="desc-container">
                <p className="video-desc">{video.description}</p>
              </div>
            </div>
          ))}
        </div>

        <Chatbot />
      </div>
    </div>
  );
}

export default Home;
